.juvederm-navigation {
  background-color: var(--emu-semantic-colors-primary-purple-100);
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    align-items: center;
    background-color: var(--emu-common-colors-transparent);
    flex-direction: row;
    padding: 0;
    position: relative;
    transform: none;
    transition: none;
  }

  &--mobile {
    .modal-wrapper {
      display: block;
      transform: translateX(100%);
      transition: transform .35s ease-in-out;

      &.is-open {
        transform: translateX(0);
      }
    }

    .modal-content {
      background-color: var(--emu-semantic-colors-primary-purple-100);
      height: 100%;
      margin: 0;
      max-width: none;
      padding: 0;
      width: 100%;

      .main-close {
        color: var(--emu-common-colors-white);

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }
  
  &--desktop {
    display: none;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      display: flex;
    }
  }

  &-logo {
    margin-top: 5px;

    svg {
      display: block;
      height: 30px;
      width: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      display: none;
    }
  }

  > .container:nth-child(3) { /* it's not possible to set a class for this element */
    bottom: 30px;
    left: 15px;
    right: 15px;
    position: absolute;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      bottom: unset;
      left: unset;
      position: relative;
      right: unset;
    }
  }

  &-wrapper-items.aaaem-container {
    margin-top: 30px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      align-items: center;
      background-color: var(--emu-common-colors-transparent);
      display: flex;
      gap: 30px;
      margin-right: 30px;
      margin-top: 0;
    }
  }

  &-wrapper-top .emu-image__link {
    display: inline-block;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      display: block;
    }
  }

  &-wrapper-top,
  &-wrapper-bottom {
    background-color: var(--emu-semantic-colors-primary-purple-100);
    display: flex;
    justify-content: space-between;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      background-color: initial;
      display: block;
    }
  }

  &-wrapper-bottom {
    align-items: center;
    border-top: 1px solid var(--emu-semantic-colors-secondary-purple-100);
    padding-top: 30px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      background-color: var(--emu-common-colors-transparent);
      border-top: 0;
      display: flex;
      gap: 30px;
      padding-top: 0;
    }
  }

  .emu-navigation {
    &__content-wrapper {
      left: initial;
      position: relative;
      top: initial;

      nav {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(--emu-common-colors-transparent);
        }
      }
    }

    &__item {
      &-parent {
        border-top: 1px solid var(--emu-semantic-colors-secondary-purple-100);
        color: var(--emu-common-colors-white);
        font-size: 24px;
        line-height: 27px;
        opacity: 0.9;
        padding-bottom: 42px;
        padding-top: 10px;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          border-top: 0;
          cursor: pointer;
          font-size: 18px;
          line-height: 20px;
          padding-bottom: 5px;
          padding-top: 5px;
          position: relative;
          text-transform: uppercase;

          &:hover {
            opacity: 1;

            > .emu-navigation__menu--level-1 {
              display: flex;
              opacity: 1;
              pointer-events: all;
              transform: translateY(20px);
            }

            &::before {
              width: 100%;
            }
          }

          &::before {
            background-color: var(--emu-common-colors-white);
            bottom: 0;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            transition: width .2s ease-in-out;
            width: 0;
          }
        }
      }

      a {
        display: inline-block;
        font-size: 24px;
        line-height: 27px;        
        margin-left: 0px;
        opacity: 0.9;
        border: 0;
        padding-left: 0px;
        
        @include mq($emu-semantic-sizing-breakpoints-large) {
          padding-bottom: var(--emu-component-lists-navigation-text-padding-bottom-narrow);
          padding-left: var(--emu-component-lists-navigation-text-padding-left-narrow);
          padding-right: var(--emu-component-lists-navigation-text-padding-right-narrow);
          padding-top: var(--emu-component-lists-navigation-text-padding-top-narrow);
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-left: 0;
          opacity: 1;
          font-size: 18px;
          line-height: 20px;
          padding-left: 0;
          padding-right: 0;
          text-transform: uppercase;          
          position: relative;
        }

        &:hover {
          background-color: var(--emu-common-colors-transparent);
          opacity: 1;
          &::before {
            @include mq($emu-semantic-sizing-breakpoints-x-large) {
            width: 100%;
            }
          }
        }
        &::before {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(--emu-common-colors-white);
          bottom: 0;
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          transition: width .2s ease-in-out;
          width: 0;
          }
        }
      }
      &-parent {
        a {
          opacity: 0.6;
          margin-left: 20px;
          font-size: 16px;
          line-height: 25px;
          padding-left: var(--emu-component-lists-navigation-text-padding-left-narrow);
          
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            opacity: 1;
            margin-left: 0px;
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }

    &__menu {
      &--level-0 {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 250px);
        overflow-y: scroll;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          flex-direction: row;
          max-height: none;
          overflow-y: visible;
        }

        > .emu-navigation__item-parent:first-child {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            margin-right: 30px;
          }
        }
        > .emu-navigation__item-parent:not(:first-of-type) {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            margin-left: 30px;
          }
        }
      }

      &--level-1 {
        border: 0;
        display: block;
        position: relative;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background: var(--emu-common-colors-white);
          border: 0;
          flex-direction: column;
          min-width: 174px;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 22px;
          transition: all .2s ease-in-out;

          &::before {
            background-color: var(--emu-common-colors-transparent);
            content: "";
            display: block;
            height: 15px;
            margin-top: -15px;
            position: absolute;
            width: 100%;
          }
        }

        .emu-navigation__item {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            a {
              color: var(--emu-semantic-colors-secondary-purple-100);
              display: block;
              font-size: 16px;
              line-height: 25px;
              padding-bottom: var(--emu-component-lists-navigation-text-padding-bottom-wide);
              padding-left: var(--emu-component-lists-navigation-text-padding-left-wide);
              padding-right: var(--emu-component-lists-navigation-text-padding-right-wide);
              padding-top: var(--emu-component-lists-navigation-text-padding-top-wide);
              text-transform: none;

              &:hover {
                background-color: var(--emu-semantic-colors-transparent-purple-300);
                box-shadow: inset 3px 0 0 -1px var(--emu-semantic-colors-secondary-purple-100);
                color: var(--emu-semantic-colors-secondary-purple-100);
              }
            }
          }

          &:first-of-type {
            margin-top: 8px;

            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  &-findaclinic.aaaem-button {
    &:active {
      background-color: var(--emu-common-colors-transparent);
      outline: none;
    }

    &:focus,
    &:hover {
      background-color: var(--emu-semantic-colors-transparent-white-100);
    }
  }

  .cmp-languagenavigation {
    position: relative;
    z-index: var(--emu-semantic-other-z-index-language-navigation);

    &__switcher {
      color: var(--emu-common-colors-white);
      display: inline-flex;
      font-size: 18px;
      line-height: 33px;

      &.active {
        svg {
          transform: scaleY(-1);
        }
      }

      span {
        align-items: center;
        display: flex;
        margin-left: 5px;
      }

      svg {
        display: inline-block;
        height: 20px;
        transform: scaleY(1);
        transition: all .2s ease-in-out;
        width: 20px;
      }
    }

    &__group {
      background-color: var(--emu-common-colors-white);
      display: flex;
      flex-direction: column;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: all .2s ease-in-out;
      transform: translateY(-100%);

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-top: 13px;
        top: initial;
        transform: translateY(-20px);
      }

      &.active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(calc(-100% - 12px));

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          transform: translateY(0);
        }
      }
    }

    &__item-link {
      display: block;
      font-size: 16px;
      line-height: 25px;
      padding-bottom: 10px;
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 10px;
      text-decoration: none;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        color: var(--emu-semantic-colors-secondary-purple-100);
      }

      &:hover {
        background-color: var(--emu-semantic-colors-transparent-purple-300);

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(--emu-semantic-colors-transparent-purple-300);
          box-shadow: inset 3px 0 0 -1px var(--emu-semantic-colors-secondary-purple-100);
        }
      }
    }
  }
}